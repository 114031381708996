import React from 'react';
import { Hidden } from '@material-ui/core';
import Banner from 'src/images/merchant/campaign/general_cashback_202309/banner.png';
import BannerSp from 'src/images/merchant/campaign/general_cashback_202309/banner-sp.png';
import styles from './CashbackTopBanner202309.module.scss';

export default function CashbackTopBanner202309() {
  return (
    <section>
      <Hidden xsDown>
        <div className={styles.background}>
          <img src={Banner} width={1440} height={450} alt="banner" />
        </div>
      </Hidden>
      <Hidden smUp>
        <img src={BannerSp} width="100%" height="auto" alt="banner-sp" />
      </Hidden>
    </section>
  );
}
