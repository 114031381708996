import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { SectionNotes } from 'src/components';

const TERMS = [
  '全額キャッシュバックの当選はお一人一回までで、期間中のお買い物総額を対象として抽選でキャッシュバックされます（最大10万円）。',
  '株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。',
  '当選者には2023年10月31日（火）までにキャッシュバックをいたします。',
  'キャッシュバックは、ペイディのご利用に応じた請求金額より差し引く方法による付与されます。いかなる場合でも、キャッシュバック金額を現金で交付することはできませんのでご了承ください。',
  '同期間に開催中の他のキャンペーンと併せて月間当選上限額は10万円になります。',
  'Amazonでのお買い物の場合、期間中に発送（出荷）された商品の総額がキャンペーン対象となります。Amazon Primeのお申し込み、Amazon Musicの定額ストリーミングサービスのプラン料金など期間中に決済の発生しないサービスの支払いはキャッシュバックの対象外となります。',
  '期間中のお買い物でキャンセルされた商品は、キャッシュバックの対象外となります。',
  '不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。',
  <>
    その他条件は
    <OutboundLink
      href="https://terms.paidy.com/cashback/"
      target="_blank"
      rel="noopener noreferrer"
    >
      ペイディキャッシュバック及びクーポン利用規約
    </OutboundLink>
    に準じます。
  </>,

  <>
    ペイディに関するお問い合わせは{' '}
    <OutboundLink
      href="https://cs.paidy.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      ヘルプ
    </OutboundLink>
    からご連絡ください。
  </>,
];

export default function SectionNotes202309({ id, terms = TERMS }) {
  return <SectionNotes id={id} terms={terms} />;
}
